import React from "react";
import { Link } from "@reach/router";
import Forbes from "../../../assets/img/forbes.png";
import Seznam from "../../../assets/img/seznam.png";
import PozitivniZpravy from "../../../assets/img/pozitivniZpravy.png";
import HN from "../../../assets/img/hn_logo.svg";
import E15 from "../../../assets/img/e15trans.png";
import StartupJobs from "../../../assets/img/startupJobs.png";
import { navigate } from "gatsby";
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";
import styled from "styled-components";
import EmailDotaznikPotvrzeni from "../../../components/emails/EmailDotaznikPotvrzeni";
import { renderToString } from "react-dom/server";

const Nezavazne = styled.div`
  font-family: Visuelt-Medium;
  font-size: 14px;
  color: #243A58;
  text-align: center;
  background: #C5DCFB;
  border-radius: 3px;
  padding: 5px 10px;
  width: fit-content;
  margin-bottom: 20px;
`;

const Prihlaseni = styled.p`
  font-size: 16px !important;
  color: #9f9f9f !important;

  a {
    font-size: 16px;
    color: #9f9f9f;
  }
`;

const SocialProof = styled.div`
  h3 {
    font-family: Visuelt-Medium;
    font-size: 19px;
    color: #272727;
    margin-bottom: 25px;
    margin-top: 50px;
  }

  ul {
    max-width: 800px;

    @media (max-width: 1000px) {
      margin-bottom: 80px;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px;
      margin-bottom: 20px;

      @media (max-width: 899px) {
        margin-right: 25px;
        margin-bottom: 20px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &:hover {
        img {
          filter: grayscale(100%) brightness(0%) opacity(60%);
        }
      }

      img {
        filter: grayscale(100%) brightness(0%) opacity(35%);
        max-height: 26px;
        width: auto;

        @media (max-width: 899px) {
          max-height: 25px;
        }

        @media (max-width: 350px) {
          max-height: 21px;
        }
      }

      .jobs {
        max-height: 22px;
        width: auto;

        @media (max-width: 899px) {
          max-height: 23px;
        }

        @media (max-width: 350px) {
          max-height: 20px;
        }
      }

      .seznam {
        margin-bottom: -5px;
      }

      .pozitivni {
        max-height: 22px;
        width: auto;

        @media (max-width: 899px) {
          max-height: 22px;
        }

        @media (max-width: 350px) {
          max-height: 22px;
        }
      }
    }
  }
`;

const Zacatek = ({userData, updateData}) => {
  let uprava = userData;

  function zacit() {
    navigate("/nabidka/dotaznik/stav-blizkeho");
  }

  return (
    <DotaznikLayout phase={1} progress={20} isSupp>
      {/* <Nezavazne>Naprosto nezávazné</Nezavazne> */}
      <h1>Sestavte si pohřeb podle svých představ.<br/>Finální cenu dostanete během minut.</h1>
      <p>Nezávazně odpovězte na následnujích 7 otázek a okamžitě získejte cenovou nabídku. Všechny ceny zobrazujeme transparentně a jsou konečné. Vše můžete kdykoliv zpětně upravit.</p>
      <button onClick={() => zacit()}>Začít</button>
      <Prihlaseni>Jste již naši zákazníci? Přihlásit se můžete <Link to="/prihlasit-se">zde</Link>.</Prihlaseni>

      <SocialProof>
        <h3>Můžete nás znás z:</h3>
        <ul>
          <li>
            <a target="_blank" href="https://www.e15.cz/byznys/obchod-a-sluzby/digitalizovane-pohrebnictvi-start-up-goodbye-chce-revoluci-v-oboru-1379081">
              <img src={E15} alt="Digitalizované pohřebnictví. Start-up Goodbye chce revoluci v oboru"/>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://forbes.cz/pohrebnictvi-funguje-uz-150-let-stejne-ted-to-chce-zmenit-cesky-startup/">
              <img src={Forbes} alt="Pohřebnictví funguje už 150 let stejně. Teď to chce změnit český startup"/>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://podcasty.hn.cz/c1-67010510-co-nas-ceka-po-smrti-i-silne-konzervativni-obor-jako-pohrebnictvi-ma-sve-trendy">
              <img className="pozitivni" src={HN} alt="Co nás čeká po smrti? I silně konzervativní obor jako pohřebnictví má své trendy"/>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.seznamzpravy.cz/clanek/ani-smrt-nas-nerozdeli-stale-vice-lidi-chce-mit-data-v-digitalnim-trezoru-172228">
              <img className="seznam" src={Seznam} alt="Ani smrt nás nerozdělí. Stále více lidí chce mít data v digitálním trezoru"/>
            </a>
          </li>
        </ul>
      </SocialProof> 

      <Link to="/" className="zpet">← Zpět</Link>

    </DotaznikLayout>
  );
};




export default Zacatek;